.profile_section_container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  background: #000000;
}

.profile_details {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
  flex-direction: column;
  width: 400px;
  height: 500px;
  padding: 15px 20px;
  backdrop-filter: blur(6px);
  background-color: #ffffff;
  border-radius: 6px;
}
.profile_details img {
  border-radius: 50%;
  width: 185px;
  height: 185px;
  border: 1px solid #000000;
  box-shadow: 0 0 6px 1px #c2c0c0ba;
}
.profile_info {
  text-align: center;
}
.profile_name {
  font-size: 25px;
}
.profile_occupation,
.profile_name {
  color: #232323;
  font-weight: 500;
}
.profile_description {
  width: 86%;
  text-align: justify;
}
.p_info_container {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 27px;
  margin-bottom: 10px;
}

.profile_section_container h2 span {
  margin-right: 9px;
}

@media screen and (min-width: 1024px) {
  .profile_section_container {
    height: 100vh;
  }
}
@media screen and (max-width: 1023px) {
  .profile_section_container {
    height: 100vh;
  }
}
@media screen and (max-width: 940px) {
  .profile_section_container h2 {
    width: 90%;
  }
}

@media screen and (max-width: 720px) {
  .p_info_container {
    width: 90%;
  }
}
@media screen and (max-width: 580px) {
  .profile_section_container {
    gap: 0;
    height: auto;
  }
  .p_info_container {
    flex-direction: column;
    margin-top: 39px;
    margin-bottom: 30px;
  }
  .profile_section_container h2 {
    margin-top: 41px;
  }
}

@media screen and (max-width: 480px) {
  .profile_section_container h2 {
    margin-top: 54px;
  }
  .profile_details {
    width: 90%;
  }
}

@media screen and (max-width: 420px) {
}
